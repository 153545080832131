import { Date_YYYY_MM_DD, DateType } from "@/types/time"

export type HistoryWeatherRequestPayload = {
  day: string
}

export type WeatherResponsePayload = Array<{
  hour?: number
  temp?: number
  clouds?: number
  feelsLike?: number
  pressure?: number
  humidity?: number
  windSpeed?: number
  windDegree?: number
  weather?: [WeatherSummary]
  rain?: HourlyReading
  snow?: HourlyReading
}>

export type WeatherSummary = {
  id?: number
  main?: string
  description?: string
  icon?: string
}

export type HourlyReading = {
  "1h"?: number
  "3h"?: number
}

export enum WeatherProvider {
  WeatherStack = "WeatherStack",
  AerisWeather = "AerisWeather",
}

export type Weatherstatus = {
  description: string
  hour: DateType
  id: number
  temp: number
}

export type WeatherDataResponse = {
  minFeelsLike: number
  id: number
  sunhour: number
  maxFeelsLike: number
  minWindchill: number
  minPressure: number
  minWindDegree: number
  minDewpoint: number
  maxClouds: number
  maxWindgust: number
  minWindSpeed: number
  astro: {
    moonIllumination: number
    moonPhase: string
    moonrise: string
    moonset: string
    sunrise: string
    sunset: string
  }
  insertedAt: string
  maxDewpoint: number
  maxTemp: number
  minClouds: number
  weather: Weatherstatus[]
  minHeatindex: number
  minPrecip: number
  maxWindDegree: number
  maxUvIndex: number
  minHumidity: number
  maxWindSpeed: number
  minWindgust: number
  updatedAt: DateType
  maxPrecip: number
  uvIndex: number
  maxHeatindex: number
  minTemp: number
  date: Date_YYYY_MM_DD
  totalsnow: number
  maxPressure: number
  maxWindchill: number
  maxHumidity: number
  avgtemp: number
  projectId: number
  minUvIndex: number
}
